import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import Carousel from 'components/Carousel';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium, BaseTransparentP, BaseP } from 'components/typography';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import {
  PartnersWrapper,
  WhiteContainer,
  ImageBox,
  GatsbyImage,
} from 'components/pages/Partners/styled';
import { RelativeWrapperWithImage } from 'components/pages/Integrations/styled';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/international-shipping/hero-bg.png';
import HeroImage from 'images/international-shipping/hero-image.png';
import DangerousProducts from 'images/international-shipping/dangerous-products.png';
import InternationalSalesTax from 'images/international-shipping/international-sales-tax.png';
import ListIcon1 from 'images/international-shipping/gadgets-icon.png';
import ListIcon2 from 'images/international-shipping/weapons.png';
import ListIcon3 from 'images/international-shipping/aerosols.png';
import BoxIcon1 from 'images/international-shipping/cargo-damage.png';
import BoxIcon2 from 'images/international-shipping/stuck-shipments.png';
import BoxIcon3 from 'images/international-shipping/product-export.png';
import AutomationIcon1 from 'images/international-shipping/inventory-management.png';
import AutomationIcon2 from 'images/international-shipping/shipping-process.png';
import AutomationIcon3 from 'images/international-shipping/shipping-labels.png';
import AutomationIcon4 from 'images/international-shipping/shipment-tracking.png';
import AutomationIcon5 from 'images/international-shipping/improved-order-accuracy.png';
import ArrowImg from 'images/international-shipping/international-sales tax-brackets.png';

const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

const PageContainer = styled.div<RelativeWrapperWithImage>`
  margin-bottom:0;
  width:100%;
  p {
    color: rgb(75, 75, 75);
  }
  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

`;
const MainSection = styled.div<HeroSection>`
  position:relative;

  #hero-section {
    padding:70px 20px;
  }

  #hero-section .column-half {
    width: 50%;
    padding-right: 0;
    position: relative;
    padding-top: 45px;
    padding-bottom: 80px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #hero-section .column-half p{
    padding-right:60px;
  }

  #hero-section .column-half.last {
    width: 50%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #hero-section .column-half img{
      width: 100%;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:0;
    object-fit: cover;
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  @media only screen and (max-width:981px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img{
      width: 100%;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }

`;


const ShippingSectionURL = styled.div<HeroSection>`
#tab-navigation {
  padding-top: 50px;
  padding-bottom: 50px;
}
#tab-navigation h3:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 4px;
  background: #05ad89;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40px;
  margin: auto;
}

#tab-navigation h3 {
  text-align:center;
  position:relative;
  color:#4b4b4b;
  font-size: 17px;
  font-weight: 400;
}

@media only screen and (max-width:981px) {
  
  #tab-navigation {
    padding: 40px 25px 20px 25px;
  }

  #tab-navigation div {
    width: 100%;
    padding-bottom: 45px;
  }

  #tab-navigation div:nth-child(4) {
    padding-bottom:0;
  }

}

`;

const ShippingSectionProducts = styled.div<HeroSection>`
  padding-top:25px;

  button {
    margin-left:auto;
    margin-right:auto;
  }

  #dangerous-products .column-half {
    width: 60%;
    padding-right: 2%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #dangerous-products  .column-half.last {
    width: 40%;
    padding-right: 0;
    z-index: 22;
    position: relative;
    display: inline-block;
    padding-top: 100px;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #dangerous-products h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:35px;
  }

  #dangerous-products h1 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:35px;
  }

  #international-sales-tax .column-half {
    width: 60%;
    padding-right: 2%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #international-sales-tax p {
    color:#4b4b4b;
    line-height:23px;
    font-size:15px;
  }

  #international-sales-tax  .column-half.last {
    width: 40%;
    padding-right: 0;
    z-index: 22;
    position: relative;
    display: inline-block;
    padding-top: 100px;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #international-sales-tax h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:35px;
  }

  .ban-list img{
    display: inline-block;
    max-width: 25px;
    margin-bottom: -11px;
    margin-right: 10px;
  }

  .ban-list li {
    padding-bottom: 20px;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
  }

  .tax-list {
    margin-bottom:-20px;
  }

  .tax-list img {
    display: inline-block;
    max-width: 60px;
    margin-bottom: -39px;
    margin-right: 0;
    float: left;
  }

  .tax-list span {
    padding-left: 65px;
    float: left;
    display: inline-block;
    width:100%;
  }

  .tax-list li {
    padding-bottom: 20px;
    margin-bottom:40px;
  }

  #possible-problems h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 35px;
  }

  #possible-problems {
    padding-top: 50px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
  }

  #possible-problems p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  #possible-problems .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    padding:50px 20px 20px 20px;
    -webkit-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.5);
    -moz-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.5);
    box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.5);
    border-radius:20px;
    border:1px solid #cccccc;
    margin-top:50px;
    float:left;
    max-height:335px;
    min-height:335px;
  }

  .onhover {
    margin-top:-10px;
  }

  #possible-problems .column-third.last {
    margin-right:0;
  }

  #possible-problems .column-third img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-height: 100px;
    margin-top: 35px;
  }

  #possible-problems .column-third:hover {
    cursor:pointer;
  }

  #possible-problems .column-third:hover img{
    display:none;
    animation: fadeIn ease 1s;
  }

  #possible-problems .column-third:hover p.hover{
    display:none;
    animation: fadeIn ease 1s;
  }

  #possible-problems .column-third:hover p.onhover{
    display:block;
    animation: fadeIn ease 1s;
  }
  @media only screen and (min-width:1024px) {
    #possible-problems .zh .column-third:hover p.onhover{
      display:block;
      animation: fadeIn ease 1s;
      margin-top:22%;
    }
  }

  #possible-problems .column-third .onhover{
    display:none;
    animation: fadeIn ease 1s;
  }

  #shipping-automation {
    max-width:1080px;
    margin-left:auto;
    margin-right:auto;
    padding-top:50px;
    padding-bottom:50px;
  }

  #shipping-automation .automation-half {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  #shipping-automation .column-half {
    width: 50%;
    display: inline-block;
    float: left;
    padding-right: 4%;
  }

  #shipping-automation .column-half.last {
    width: 50%;
    padding-right: 0;
  }


  #shipping-automation .column-third{
    margin-top:30px;
    width:29%;
    margin-right:5%;
  }

  #shipping-automation .column-third.last{
    margin-right:0;
  }

  #shipping-automation img {
    min-height: 85px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
  }

  #shipping-automation h3 {
    color: #4b4b4b;
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
  }

  #shipping-automation h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 35px;
  }

  #shipping-automation p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @media only screen and (max-width:981px) {
    
    #dangerous-products {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #dangerous-products h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #dangerous-products h1 {
      font-size:28px;
      margin-bottom:20px;
    }

    #dangerous-products .column-half {
      width: 100%;
      padding: 0;
    }

    #dangerous-products .column-half.last {
      width: 100%;
      padding: 0;
    }

    #possible-problems {
      padding-top:40px;
    }

    #possible-problems h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #possible-problems .column-third {
      width:100%;
      display:inline-block;
      margin-right:0;

      margin-top: 20px;
      max-height: 275px;
      min-height: 275px;
    }

    #possible-problems .column-third img { 
      margin-top:10px;
    }

    #possible-problems {
      padding-right:25px;
      padding-left:25px;
    }

    #possible-problems h2 {
        margin-bottom: 20px;;
    }

    #possible-problems p {
        margin-bottom: 15px;
    }

    #international-sales-tax {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #international-sales-tax h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #international-sales-tax .column-half {
      width: 100%;
      padding: 0;
    }

    #international-sales-tax .column-half.last {
      width: 100%;
      padding: 0;    
      padding-top: 20px;
    }

    #dangerous-products {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #dangerous-products h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #shipping-automation .column-half {
      width: 100%;
      padding: 0;
    }

    #shipping-automation .column-half.last {
      width: 100%;
      padding: 0;
    }

    #shipping-automation {
      padding-left:25px;
      padding-right:25px;
      padding-top:40px;
    }

    #shipping-automation h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #shipping-automation .column-third {
      width:100%;
      display:inline-block;
      margin-right:0;
      margin-top: 20px;
    }

    .tax-list {
      margin-bottom:0;
    }

  }

`;

const LanguageSeparator = styled.div`
  .en .zh {
    display:none;
  }

  .zh .en {
    display:none; 
  }
`;

const ContentSmall = styled.div<TextDesc>`
  position: relative;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallSpan = styled.span<TextDesc>`
  position: relative;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div<BasicDiv>`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div<BasicDiv>`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const test = styled.div`

`;

const HeroIMG = styled.img`

`;

const TabNavigation = styled.div<BasicDiv>`
  width:25%;
  display:inline-block;

`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 30px;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
<<<<<<< HEAD
<<<<<<< HEAD
    padding: 0px 0px 25px 0px;
    width:100%;
=======
    padding: 25px 0px 25px 50px;
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    padding: 0px 0px 25px 0px;
    width:100%;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const InternationalShipping = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>International Shipping | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "resources-internal-shipping" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "international-shipping-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "international-shipping-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "international-shipping-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "international-shipping-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Make the right choice by picking Shipkoo as your fulfillment partner for your international shipping to reach any countries and earn bigger profits!" />
        <meta property="og:title" content={intl.formatMessage({ id: "resources-internal-shipping" })} />
        <meta property="og:description" content="Make the right choice by picking Shipkoo as your fulfillment partner for your international shipping to reach any countries and earn bigger profits!" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "resources-internal-shipping" })} />
        <meta name="twitter:description" content="Make the right choice by picking Shipkoo as your fulfillment partner for your international shipping to reach any countries and earn bigger profits!" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section">
            <img className="hero-bg" src={HeroBg} alt="international shipping hero background" />
            <Row>
                <div className="column-half">
                  <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-international-shipping" })}</Breadcrumbs>
                  <HeroHeader>{intl.formatMessage({ id: "is-header" })} <br/>{intl.formatMessage({ id: "is-subheader" })}</HeroHeader>
                  <HeroText>{intl.formatMessage({ id: "is-desc" })}</HeroText>
                </div>
                <div className="column-half last">
                  <HeroIMG src={HeroImage} alt="international shipping hero image" />
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <ShippingSectionURL>
          <Row>
            <div id="tab-navigation">
              <TabNavigation>
                <a href="#dangerous-products">
                  <h3>{intl.formatMessage({ id: "is-dangerous-products-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#possible-problems">
                  <h3>{intl.formatMessage({ id: "is-possible-problems-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#international-sales-tax">
                  <h3>{intl.formatMessage({ id: "is-international-sales-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#shipping-automation">
                  <h3>{intl.formatMessage({ id: "is-shipping-automation-tab" })}</h3>
                </a>
              </TabNavigation>
              <Clear></Clear>
            </div> 
          </Row>
        </ShippingSectionURL>
        <ShippingSectionProducts>
          <div id="dangerous-products">
            <Row>
              <div className="column-half">
                <h2>
                  {intl.formatMessage({ id: "is-dangerous-products-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "is-dangerous-products-desc" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "is-dangerous-products-list-title" })}
                </ContentSmall>
                <ul className="ban-list">
                  <li><img src={ListIcon1} alt="products with batteries icon" />{intl.formatMessage({ id: "is-dangerous-products-list1" })}</li>
                  <li><img src={ListIcon2} alt="weapons icon" />{intl.formatMessage({ id: "is-dangerous-products-list2" })}</li>
                  <li><img src={ListIcon3} alt="aerosols and other flammable products icon" /> {intl.formatMessage({ id: "is-dangerous-products-list3" })}</li>
                  <Clear></Clear>
                </ul>
              </div>
              <div className="column-half last">
                <img src={DangerousProducts} alt="dangerous products in international shipping" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </ShippingSectionProducts>
        <ShippingSectionProducts>
          <div id="possible-problems">
            <Row>
              <h2>
                {intl.formatMessage({ id: "is-possible-problems-title" })}
              </h2>
              <LanguageSeparator>
                <div className={intl.formatMessage({ id: "language" })}>
                  <div className="en">
                    <ContentSmall>
                      {intl.formatMessage({ id: "is-possible-problems-desc1" })} 
                    </ContentSmall>  
                    <ContentSmall>
                      {intl.formatMessage({ id: "is-possible-problems-desc2" })}
                    </ContentSmall>
                  </div>
                  <div className="zh">
                    <ContentSmall>
                      {intl.formatMessage({ id: "is-possible-problems-desc1" })} &nbsp;{intl.formatMessage({ id: "is-possible-problems-desc2" })} 
                    </ContentSmall>  
                  </div>
                </div>
              </LanguageSeparator>
              <div className={intl.formatMessage({ id: "language" })}>
                <div className="column-third">
                  <img src={BoxIcon1} alt="cargo damage and lost parcels icon" />
                  <p className="hover">
                    {intl.formatMessage({ id: "is-possible-problems-column1-title" })}
                  </p>  
                  <p className="onhover">
                    {intl.formatMessage({ id: "is-possible-problems-column1-desc" })}
                  </p>
                </div>
                <div className="column-third">
                  <img src={BoxIcon2} alt="shipment stuck in customs icon" />
                  <p className="hover">
                    {intl.formatMessage({ id: "is-possible-problems-column2-title" })}
                  </p>  
                  <p className="onhover">
                    {intl.formatMessage({ id: "is-possible-problems-column2-desc" })}
                  </p>
                </div>
                <div className="column-third last">
                  <img src={BoxIcon3} alt="product returns icon" />
                  <p className="hover">
                    {intl.formatMessage({ id: "is-possible-problems-column3-title" })}
                  </p>  
                  <p className="onhover">
                    {intl.formatMessage({ id: "is-possible-problems-column3-desc" })}
                  </p>
                </div>
                <Clear></Clear>
              </div>
            </Row>
          </div>
        </ShippingSectionProducts>
        <ShippingSectionProducts>
          <div id="international-sales-tax">
            <Row>
              <div className="column-half">
                <h2>
                  {intl.formatMessage({ id: "is-sales-tax-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "is-sales-tax-desc" })} 
                </ContentSmall>
                <ContentSmall>
                  <ul className="tax-list">
                    <li><img src={ArrowImg} alt="list arrow icon" /> <span>{intl.formatMessage({ id: "is-sales-tax-list1" })}</span></li>
                    <li><img src={ArrowImg} alt="list arrow icon" /> <span>{intl.formatMessage({ id: "is-sales-tax-list2" })}</span></li>
                    <li><img src={ArrowImg} alt="list arrow icon" /> <span>{intl.formatMessage({ id: "is-sales-tax-list3" })}</span></li>
                    <Clear></Clear>
                  </ul>
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "is-sales-tax-bottom-desc" })}
                </ContentSmall>
              </div>
              <div className="column-half last">
                <img src={InternationalSalesTax} alt="international sales tax" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </ShippingSectionProducts>
        <ShippingSectionProducts>
          <Link to="/contact">
            <ArrowButton >{intl.formatMessage({ id: "drop-a-line-button" })}</ArrowButton>
          </Link>
          <div id="shipping-automation">
            <Row>
              <h2>
                {intl.formatMessage({ id: "is-shipping-automation-title" })}
              </h2>
              <ContentSmall>
                {intl.formatMessage({ id: "is-shipping-automation-desc1" })}
              </ContentSmall>  
              <ContentSmall>
                {intl.formatMessage({ id: "is-shipping-automation-desc2" })}
              </ContentSmall>
              <div className="column-third">
                <img src={AutomationIcon1} alt="inventory management icon" />
                <h3>
                  {intl.formatMessage({ id: "is-shipping-automation-column1-title" })}
                </h3>  
                <p>
                  {intl.formatMessage({ id: "is-shipping-automation-column1-desc" })}
                </p>
              </div>
              <div className="column-third">
                <img src={AutomationIcon2} alt="shipping process automation icon" />
                <h3>
                  {intl.formatMessage({ id: "is-shipping-automation-column2-title" })}
                </h3>  
                <p>
                  {intl.formatMessage({ id: "is-shipping-automation-column2-desc" })}
                </p>
              </div>
              <div className="column-third last">
                <img src={AutomationIcon3} alt="shipping labels generation icon" />
                <h3>
                  {intl.formatMessage({ id: "is-shipping-automation-column3-title" })}
                </h3>  
                <p>
                  {intl.formatMessage({ id: "is-shipping-automation-column3-desc" })}
                </p>
              </div>
              <Clear></Clear>
            </Row>
            <div className="automation-half">
              <Row>
                <div className="column-half">
                  <img src={AutomationIcon4} alt="shipment tracking icon" />
                  <h3>
                    {intl.formatMessage({ id: "is-shipping-automation-column4-title" })}
                  </h3>  
                  <p>
                    {intl.formatMessage({ id: "is-shipping-automation-column4-desc" })}
                  </p>
                </div>
                <div className="column-half last">
                  <img src={AutomationIcon5} alt="improved order accuracy icon" />
                  <h3>
                    {intl.formatMessage({ id: "is-shipping-automation-column5-title" })}
                  </h3>  
                  <p>
                    {intl.formatMessage({ id: "is-shipping-automation-column5-desc" })}
                  </p>
                </div>
                <Clear></Clear>
              </Row>
            </div>
            <p>  
              {intl.formatMessage({ id: "is-shipping-automation-bottom-desc" })}
            </p> 
          </div>
        </ShippingSectionProducts>
      </PageContainer>
    </Layout>
  );
};

export default InternationalShipping;
